import React from 'react'
import './Footer.css'

export default function Footer() {
  return (
    <footer className='footerDiv'>
  <div className="copyright">
    <p>Copyright &copy; 2023, All Right Reserved- AlgoGeeks</p>
  </div>
  <div className='contact' id='Contact'>
      <p>Contact: 6204349645, 8292933300</p>
  </div>
  
</footer>
  )
}
