export const CardData=[
    {
        Heading:"Step 1",
        Writeup:"Understand concepts in Live classes with instructors from Top Product Based Companies",
        img:"/images/goal1.jpeg",

    },
    {
        Heading:"Step 2",
        Writeup:"We believe setting well-defined goals is half the battle. Hence, we will help you set smart goals to set you up for success.",
        img:"/images/goal22.jpeg",

    },
    {
        Heading:"Step 3",
        Writeup:"Unlock the Path to Your Dreams: Discover Your Potential through an Internship or Placement",
        img:"/images/goal3.jpeg",

    }
]