export const Mentor_data=[
    {
        Name:"Aman Kumar",
        College: "IIT BHU",
        Company:"ServiceNow",
        Offers:"Amazon, INDmoney, Dezerv, Synaptic",
        Desc:"Passionate about data structure and it's Implementation",
        Photo:"/images/aman.jpeg",

    },
    {
        Name:"Shubham Diwakar",
        College: "IIT BHU",
        Company:"Google",
        Offers:"Cred, ThoughtSpot",
        Desc:"The secret to my programming success? The Algorithm Wizard, of course!",
        Photo:"/images/shubham.jpeg",

    },
    {
        Name:"Sanika Jain",
        College: "IIT BHU",
        Company:"Oracle",
        WorkedAt:"Google",
        Desc:"Ignite Your Coding Passion with Expert Algorithmic Guidance.",
        Photo:"/images/sanika.jpeg",

    },
    {
        Name:"Ayush Gupta",
        College: "IIT GUWAHATI",
        Company:"Enphase",
        WorkedAt:"Microsoft",
        Desc:"Navigating Data Structures with a Pro at Your Side.",
        Photo:"/images/ayush.jpeg",

    }
]